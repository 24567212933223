import "../styles/main.scss"
import { db } from "./firebase"
import { generateUUID } from './helpers';
import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';

/*
sessionStorage structure
  currentGame: {
    gameId: null,
    mode: null,
    startTime: null,
    currentWord: null,
    endTime: null,
    endReason: null,
    duration: 0,
    startWord: null,
    targetWord: null,
    strokes: 0,
    attempts: [],
    validAttempts: []
  },
  userId: null,
  userEmail: null,
  lastURL: '/',
  last_completed: null,
  sessionId: null,
  history: []
}
*/

export function writeObject(key, object) {
  window.sessionStorage.setItem(key, JSON.stringify(object))
}

export function readObject(key) {
  var object = JSON.parse(window.sessionStorage.getItem(key))
  return object
}

export function writeGameState(key, value) {
  var currentGame = readObject('currentGame')
  currentGame[key] = value
  writeObject('currentGame', currentGame)
}

export function blankGame() {
  return {
      gameId: null,
      mode: null,
      startTime: null,
      currentWord: null,
      endTime: null,
      endReason: null,
      duration: 0,
      startWord: null,
      targetWord: null,
      strokes: 0,
      attempts: [],
      validAttempts: []
  }
}

export function populateSession() {
  // check if session already exists, if not, create structure
  if ( window.sessionStorage.length === 0 ) {
    writeObject('sessionId', generateUUID())
    writeObject('userId', null)
    writeObject('userEmail', null)
    writeObject('lastURL', '/')
    writeObject('last_completed', null)
    writeObject('userId', null)
    writeObject('history', [])
    writeObject('currentGame', blankGame())
  } else {
    console.log('populate data')
  }
}

export function updateLastURL() {
  writeObject('lastURL', window.location.pathname)
}


export async function remoteLastPlayedTime() {
  var userId = readObject('userId')
  if ( userId !== null ) {
    var lastCompletedDoc = doc(db, 'users', userId)
    var docSnap = await getDoc(lastCompletedDoc)
    if (docSnap.exists()) {
      var data = docSnap.data()
      if  ( data.last_completed ) {
        writeObject('last_completed', data.last_completed)
      }
    } else {
      // doc.data() will be undefined in this case
      console.log(`User: ${userId} does not have a last completed time.`);
    }
  } else {
    console.log('User is not signed in; cannot retrieve last played game.')
  }
}

export async function remoteLogGame() {
  var userId = readObject('userId')
  var currentGame = readObject('currentGame')
  if ( userId !== null ) {
    try {
      if ( currentGame.gameId !== null ) {
        currentGame['sessionId'] = readObject('sessionId')
        var collection = userId
        var docRef = doc(db, 'users', collection, 'games', currentGame['gameId'])
        currentGame['timestamp'] = serverTimestamp()
        console.log(currentGame)
        await setDoc(docRef, currentGame, { merge: true }).then(
            console.log('New Game Record added to firebase: ' + currentGame.gameId)
        )
        if ( currentGame.endReason === 'completed' ) {
          var lastCompletedDoc = doc(db, 'users', collection)
          await setDoc(lastCompletedDoc, { last_completed: currentGame.timestamp })
      }
    }} catch (error) {
      console.log(error.code + ': ' + error.message)
      console.log('Error saving game. Check connection.')
      if ( currentGame.endReason !== null ) {
        var history = readObject('history')
        history.push(currentGame)
        writeObject('history', history)
        console.log('Unsaved games:')
        console.log(history)
      }
    } 
  } else {
    if ( currentGame.endReason !== null ) {
      console.log("Didn't save, adding game state to history and will try to save again later.")
      history = readObject('history')
      history.push(currentGame)
      writeObject('history', history)
      console.log('Unsaved games:')
      console.log(history)
    }
    console.log('User must be logged in to save game data.')
  }
}

export async function updateRemoteHistory () {
  var userId = readObject('userId')
  var history = readObject('history')
  if ( userId !== null && history.length > 0 ) {
    var counter = history.length
    for ( counter; counter > 0; counter-- ) {
       try {
        var toSave = history.shift()
        console.log(toSave)
        toSave['timestamp'] = serverTimestamp()
        toSave['sessionId'] = readObject('sessionId') // just in case it didn't save last time 
        var docRef = doc(db, 'users', userId, 'games', toSave.gameId);
        await setDoc(docRef, toSave, { merge: true }).then(
          console.log(`Update game record in firebase: ${toSave.gameId}`)
        )
       } catch (error) {
         console.log('History not saving. User may not be connected to internet. Will try again later.')
         break
       }
    }
    writeObject('history', history)
  }
}

export async function resetValues () {
  var currentGame = readObject('currentGame')
  if ( currentGame.gameId !== null ) {
    if ( currentGame.endReason === null ) {
      writeGameState('endReason', 'abandoned')
      writeGameState('endTime', Date.now())
    }
    await remoteLogGame()
    writeObject('currentGame', blankGame())
  }
}
