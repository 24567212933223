<template>
    <div class="menubar" id="menu">
      <button class="burger button" id="burger" @click="menuToggle"></button>
      <router-link class="logo button" id="logo" to="/"></router-link>
      <span class="face-container" v-if="isLoggedIn">
        <button class="face" @click="faceMenuToggle"></button>

      </span>
      <span class="signin-container" v-else>
      <button class="signin button" id="signin" @click="signIn">Sign In</button>
      </span>
    </div>

    <div class="facenav" :style="faceMenu">
      <button class="faceclose button" @click="faceMenuToggle"></button>
      <div class="links">
        <router-link class="menurow" @click="faceMenuToggle()" to="/stats"> My Stats </router-link> 
        <router-link class="menurow" @click="faceMenuToggle(); signOut();" to="/">Log out</router-link> 
      </div>
    </div>
    
    <div class="sidenav" :style="menuObject">
    <button class="closebtn button" @click="menuToggle"></button>
    <div class="links">
      <router-link class="menurow" @click="menuToggle" to="/"> Play </router-link> 
      <router-link class="menurow" @click="menuToggle" to="/about"> About </router-link> 
    </div>

  </div>
  <div class="main">
    <router-view :resize="styleObject"/>
    <AdSense />
  </div>
  <Adsense
    data-ad-client="ca-pub-1795033656791848"
    data-ad-slot="1234567890">
  </Adsense>
</template>


<script>
import { VueScreenSizeMixin } from 'vue-screen-size';
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { useRouter } from 'vue-router'
import { populateSession, readObject } from './utils/session'
import { ref } from 'vue'

const userId = ref(sessionStorage.getItem('userId'))

export default {
  name: 'App',
  metaInfo: {
    title: "Ziji"
  },
  mixins: [VueScreenSizeMixin],
  data() {
    return {
      menuWidth: 0,
      faceMenuWidth: 0,
      router: useRouter(),
      loggedIn: false
    }
  },
  methods: {
    menuToggle() {
      if ( this.menuWidth === 0 ) {
        this.menuWidth = 256
      } else {
        this.menuWidth = 0
      }
      console.log(this.menuWidth)
    },
    faceMenuToggle() {
      if ( this.faceMenuWidth === 0 ) {
        this.faceMenuWidth = 256
      } else {
        this.faceMenuWidth = 0
      }
      console.log(this.faceMenuWidth)
    },
    signOut() {
      firebase.auth().signOut()
    },
    signIn() {
      this.router.push('/signin')
    },
    updateLoggedIn() {
      var userId = readObject('userId')
      var email = readObject('email')
      console.log('updating logged in state on app page: ' + userId)
      this.$store.commit('updateUserId', userId)
      this.$store.commit('updateEmail', email)
    }
  },
  mounted() {
    populateSession()
    this.updateLoggedIn()
    console.log(userId)
  },
  updated() {
    var userId = readObject('userId')
      var email = readObject('email')
      console.log('updating logged in state on app page: ' + userId)
      this.$store.commit('updateUserId', userId)
      this.$store.commit('updateEmail', email)
  },
  watch: {
    '$store.getters.userId'(newValue) {
      if ( newValue === null ) {
        this.loggedIn = false
      } else {
        this.loggedIn = true
      }
      console.log('Updated user ID STATE: ' + newValue)
    }
  },
  userId(newValue) {
      if ( newValue === null ) {
        this.loggedIn = false
      } else {
        this.loggedIn = true
      }
      console.log('Updated user usering CONSTNAT ID STATE: ' + newValue)
    },
  computed: {
    isLoggedIn() {
      return this.loggedIn
    },
    menuObject() {
      return {
        width: this.menuWidth + 'px'
      }
    },
    faceMenu() {
      return {
        width: this.faceMenuWidth + 'px'
      }
    },
    styleObject() {
      console.log(this.$vssWidth)
      var charWidth = 160;
      var gridPadding = 5;
      var mainChar = 75;
      var sideChar = 48;
      var englishSize = 12;
      var pinyinSize = 16;
      var smallChar = 16;
      var arrow = 24;
      var statsFontSize=16;
      var statsRow = 16;
      var menuRow = 28;
      var logoHeight = 24;
      var logoWidth = logoHeight*2;
      var definitionRow = 48;
      var outsideColumn = 'minmax(0, 1fr)';

      var newCharWidth = (this.$vssWidth - 3*gridPadding)/2-5;
      if ( newCharWidth > 300 ) {
        newCharWidth = 300;
      } else if ( newCharWidth < 108) {
        newCharWidth = 108;
      }


      
      var multiplier = newCharWidth/charWidth;
      console.log(multiplier);
      return {
        charWidth: Math.trunc(newCharWidth) + 'px',
        charHeight: Math.trunc(newCharWidth/.8) + 'px',
        mainChar: Math.trunc(mainChar * multiplier) + 'px',
        smallChar: Math.trunc(smallChar * multiplier) + 'px',
        sideChar: Math.trunc(sideChar * multiplier) + 'px',
        arrow: Math.trunc(arrow * multiplier) + 'px',
        englishSize: Math.trunc(englishSize * multiplier) + 'px',
        pinyinSize: Math.trunc(pinyinSize * multiplier) + 'px',
        statsRow: Math.trunc(statsRow * multiplier) + 'px',
        statsFontSize: Math.trunc(statsFontSize * multiplier) + 'px',
        menuRow: Math.trunc(menuRow * multiplier) + 'px',
        logoHeight: Math.trunc(logoHeight * multiplier) + 'px',
        logoWidth: Math.trunc(logoWidth * multiplier) + 'px',
        definitionRow: Math.trunc(definitionRow * multiplier) + 'px',
        outsideColumn: outsideColumn,
        multiplier: multiplier,
        popUpWidth: (2*newCharWidth)-20 + 'px',
        popUpHeight: (newCharWidth/.8)+5 + 'px',
        popUpTopMargin: (menuRow + statsRow + definitionRow)*multiplier+(newCharWidth/.8)/3 + 'px',
        buttonWidth: newCharWidth + 10,
        popUpLogoHeight: logoHeight*1.5*multiplier,
        popUpLogoWidth: logoWidth*3*multiplier,
        appWidth: (newCharWidth*2),
        smallFont: Math.trunc(englishSize * multiplier)
      }
    }
  }
}

</script>



<style>
#app {
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

:root {
    --main-color:#FFFFFF;
    --text-color:#191923; 
    --swirl-icon: url('./assets/dice.png');
    --accent3: #EF5B5B;
    --accent2: #20A39E;
    --accent2-dark: #0A7D79;
    --accent1: #ffba49; 
    --accent1-dark: #EFA23C;
    --pink: #FA67D9;
    --blue: #5692EB;
    --gray: #D9D9D9;
  
}

@font-face {
    font-family: 'MaShanZheng';
    src: url(./assets/MaShanZheng-Regular.ttf)
}

@font-face {
  font-family: 'DigitalNormal';
  src: url(./assets/DigitalNormal-xO6j.otf);
}；

body {
    background-color: var(--main-color);
    color: var(--text-color);
}

.main {
  display: flex;
  justify-content: center;
}

.menubar {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  grid-template-rows: 100%;
  height: 32px;
  margin-bottom: 10px;
}

.face-container {
  display: flex;
  justify-content: flex-end;
  grid-column: 3 / 4;
  grid-row: 1 / 1;
  align-content: center;
}

.face {
  background-color: var(--accent1);
  background-image: url(./assets/face.png);
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  justify-self: right;
  height:28px;
  width:28px;
  border: none;
  outline: none;
  transition: transform .2s;
  border-radius: 50%;
}

.signin-container {
  display: flex;
  justify-content: flex-end;
  grid-column: 3 / 4;
  grid-row: 1 / 1;
  align-content: center;
  transition: transform .2s;
}

.signin.button {
  height:28px;
  width:64px;
  border: none;
  outline: none;
  cursor: pointer;
  justify-self: right;
  border-radius: 5px;
  background-color: var(--accent3);
  font-weight: bold;
  color: var(--main-color);
}

.logo {
  grid-column: 2 / 3;
  grid-row: 1 / 1;
  border: none;
  outline: none;
  height: 32px;
  width: 64px;
  transition: transform .2s;
  background-image: url(./assets/zijilogo.png);
  background-repeat: no-repeat;
  background-size: contain;
  align-content: center;
  justify-self: center;
}

.burger {
  height: 28px;
  width: 28px;
  border: none;
  outline: none;
  transition: transform .2s;
  border-radius: 8px;
  background-color: transparent;
  background-image: url(./assets/menu.png);
  background-repeat: no-repeat;
  background-size: contain;
  align-content: center;
  cursor: pointer;
  justify-self: left;
}


.sidenav {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 32px 1fr;
  height: 100%; 
  width: 0px;
  position: fixed; 
  z-index: 4; 
  top: 0; 
  left: 0;
  background-color: var(--accent2); 
  overflow-x: hidden;
  transition: 0.5s; 
}

.links {
  grid-row: 2 / 3;
}

.closebtn {
  grid-row: 1 / 2;
  height:28px;
  width:28px;
  padding: 5px;
  border: none;
  outline: none;
  transition: transform .2s;
  border-radius: 50%;
  background-color: transparent;
  background-image: url(./assets/close.png);
  background-repeat: no-repeat;
  background-size: contain;
  align-content: left;
  cursor: pointer;
  justify-self: left;
}

.facenav {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 32px 1fr;
  height: 50%; 
  width: 0px;
  position: fixed; 
  z-index: 4; 
  top: 0; 
  right: 0;
  background-color: var(--accent2); 
  overflow-x: hidden;
  transition: 0.5s; 
}

.faceclose {
  grid-row: 1 / 2;
  height:28px;
  width:28px;
  padding: 5px;
  border: none;
  outline: none;
  transition: transform .2s;
  border-radius: 50%;
  background-color: transparent;
  background-image: url(./assets/close.png);
  background-repeat: no-repeat;
  background-size: contain;
  align-content: right;
  cursor: pointer;
  justify-self: right;
}

/* The navigation menu links */
.menurow {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: var(--main-color);
  font-weight: bold;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.menurow:hover {
  color: #f1f1f1;
}


.button:hover {
  transform: scale(1.25);
  -ms-transform: scale(1.25); 
  -webkit-transform: scale(1.25);
}

@keyframes rainbowshine {
  0% {
    background: linear-gradient(0.9turn, var(--accent3), var(--accent1), var(--accent2), var(--accent1), var(--accent3));
  }
  25% {
    background: linear-gradient(0.9turn, var(--accent1), var(--accent2), var(--accent1), var(--accent3), var(--accent1));
  }
  50% {
    background: linear-gradient(0.9turn, var(--accent2), var(--accent1), var(--accent3), var(--accent1), var(--accent2));
  }
  75% {
    background: linear-gradient(0.9turn, var(--accent1), var(--accent3), var(--accent1), var(--accent2), var(--accent1));
  }
  100% {
    background: linear-gradient(0.9turn, var(--accent3), var(--accent1), var(--accent2), var(--accent1), var(--accent3));
  }
}

.rainbow-shining {
  animation: rainbowshine 1s;
  animation-iteration-count: infinite;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .menurow {font-size: 18px;}
  .facenav {padding-top: 15px;}
}

</style>

