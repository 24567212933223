import { createApp } from 'vue'
import App from './App.vue'
import "./styles/main.scss"
import directives from "./directives/"
import router from "./router";
import { firebaseApp, db } from "./utils/firebase"
import { writeObject, remoteLastPlayedTime, updateRemoteHistory, remoteLogGame } from './utils/session';
import firebase from "firebase/compat/app";
import { getAuth } from 'firebase/auth'
import store from './utils/store'
import Adsense from 'vue-google-adsense/dist/Adsense.min.js'
import ScriptX from 'vue-scriptx'

const app = createApp(App)
directives(app)
app.use(router);
app.use(store)
app.use(firebaseApp)
app.use(db)
app.use(ScriptX)
app.use(Adsense)
app.mount('#app')

firebase.auth().onAuthStateChanged(user => {
  if (user) {  
      console.log('Auth state changed to logged in:')
      const auth = getAuth();
      const currentUser = auth.currentUser; 
      const email = user.email
      writeObject('userId', currentUser.uid)
      writeObject('userEmail', email)
      store.commit('updateUserId', currentUser.uid)
      store.commit('updateEmail', email)
      console.log("New Logged In: " + currentUser.uid)
      console.log(store.state)
      remoteLastPlayedTime()
      updateRemoteHistory()
      remoteLogGame()
  } else { 
      console.log('Auth state changed to logged out.')
      writeObject('userId', null)
      writeObject('userEmail', null)
      store.commit('updateUserId', null)
      store.commit('updateEmail', null)
  } 
});

